import React, { PropsWithChildren, ComponentProps } from "react";
import { Carousel } from "antd-mobile";
const styles = require("./carousel.module.less");
type Props = {} & ComponentProps<typeof Carousel>;

const MeshleCarousel = (props: PropsWithChildren<Props>) => {
  return (
    <Carousel
      className={styles["carousel-container"]}
      dots={false}
      cellSpacing={0}
      {...props}
    >
      {props.children}
    </Carousel>
  );
};
export { MeshleCarousel as Carousel };
