import { createSelector } from "reselect";
import { AppState, Device } from "@lib/models";
import { ServicesState } from "@store/reducers/services.reducer";

const getServices = (state: AppState) => state.services;

export const getAuthService = createSelector(
  [getServices],
  services =>
    services && services.authService
      ? services.authService
      : ({} as ServicesState["authService"])
);

export const getInternalizationService = createSelector(
  [getServices],
  services =>
    services && services.internalizationService
      ? services.internalizationService
      : ({} as ServicesState["internalizationService"])
);
