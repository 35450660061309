// visit https://tailwindcss.com/docs/customizing-colors to see what the colors and other props look like

const {
    colors,
    spacing,
    fontSize,
    borderRadius,
    borderWidth,
    boxShadow,
    fontWeight
} = require("./defaultVars")

module.exports = {
    "@primary-font": '"Open Sans", sans-serf',
    "@secondary-font": '"Open Sans", sans-serf',
    "@black": colors.black,
    "@white": colors.white,
    "@transparent-white": colors.transparent_white[10],
    "@primary-color": colors.purple["700"],
    "@secondary-color": colors.purple["600"],
    "@tertiary-color": colors.yellow["400"],
    "@gray-sm": colors.gray["100"],
    "@gray-md": colors.gray["400"],
    "@gray-base": colors.gray["600"],
    "@gray-lg": colors.gray["800"],
    "@red-sm": colors.red["100"],
    "@red-md": colors.red["400"],
    "@red-lg": colors.red["600"],
    "@red-xl": colors.red["800"],
    "@purple-xl": colors.purple["900"],
    "@on-primary": colors.white,
    "@on-secondary": colors.white,
    "@on-tertiary": colors.purple["900"],
    "@spacing-sm": spacing["2"],
    "@spacing-md": spacing["4"],
    "@spacing-lg": spacing["8"],
    "@spacing-xl": spacing["16"],
    "@font-size-xs": fontSize.xs,
    "@font-size-sm": fontSize.sm,
    "@font-size-base": fontSize.base,
    "@font-size-lg": fontSize.lg,
    "@font-size-xl": fontSize.xl,
    "@font-size-xxl": fontSize.xxl,
    "@border-radius-sm": borderRadius.sm,
    "@border-radius-base": borderRadius.default,
    "@border-radius-lg": borderRadius.lg,
    "@border-radius-round": "50%",
    "@border-width-default": borderWidth.default,
    "@border-width-sm": borderWidth["2"],
    "@border-width-lg": borderWidth["4"],
    "@box-shadow-base": boxShadow.default,
    "@box-shadow-md": boxShadow.md,
    "@box-shadow-lg": boxShadow.lg,
    "@box-shadow-xl": boxShadow.xl,
    "@box-shadow-inner": boxShadow.inner,
    "@box-shadow-outline": boxShadow.outline,
    "@box-shadow-none": boxShadow.none,
    "@font-weight-hl": fontWeight.hairline,
    "@font-weight-th": fontWeight.thin,
    "@font-weight-lt": fontWeight.light,
    "@font-weight-nm": fontWeight.normal,
    "@font-weight-md": fontWeight.medium,
    "@font-weight-sb": fontWeight.semibold,
    "@font-weight-bd": fontWeight.bold,
    "@font-weight-eb": fontWeight.extrabold,
    "@font-weight-bl": fontWeight.black,
    "@text-color": colors.white,
    "@banner-bg": "#564dff"
}
