import React, {
    useReducer,
    useEffect,
    CSSProperties,
    Fragment,
    useCallback,
    useLayoutEffect
} from "react"
import { TypedReducer } from "@lib/utils"
import { useSelector } from "react-redux"
import { getWindowDimensions } from "@store/selectors"
import { clamp, isEmpty } from "lodash"
import { drawCanvas, changeKnobEventListeners } from "./_wheel"
import { Slider } from "antd-mobile"
import { HSVColor } from "@store/models"
import { hsl2hsv, hsvToRgb } from "@lib/utils/color"
import wheel from "./wheel.png"
const styles = require("./colorwheel.module.less")

const maxDim = 600
type RGBColor = { r: number; g: number; b: number }
export type HSLColor = { h: number; s: number; l: number }
type Props = {
    color: HSVColor
    onColorChange: (color: RGBColor) => void
}
type State = {
    color: HSVColor
    hsl: HSLColor
    size: number
}
const reducer: TypedReducer<State> = (state, property) => ({
    ...state,
    ...property
})
const initState: State = {
    color: {} as HSVColor,
    hsl: {} as HSLColor,
    size: 0
}

const mql = window.matchMedia("(orientation: landscape)")
const ColorWheel = (props: Props) => {
    const sliderRef = React.createRef<HTMLDivElement>()
    const colorPicker = React.createRef<HTMLDivElement>()
    const container = React.createRef<HTMLDivElement>()
    const colorKnob = React.createRef<HTMLDivElement>()
    const [state, setState] = useReducer(reducer, initState)
    const dimensions = useSelector(getWindowDimensions)

    useEffect(() => {
        if (!isEmpty(props.color)) {
            setState({
                color: props.color
            })
        }
    }, [props.color])

    useLayoutEffect(() => {
        if (container.current) {
            const size = mql.matches
                ? container.current.clientHeight
                : container.current.clientWidth
            setState({ size })
        }
    }, [dimensions])

    useEffect(() => {
        if (colorPicker.current && colorKnob.current && !isEmpty(props.color)) {
            const knob = colorKnob.current
            drawCanvas(knob, state.size, props.color)
        }
    }, [state.size, props.color])

    useEffect(() => {
        if (colorKnob.current && colorPicker.current && container.current) {
            changeKnobEventListeners(
                true,
                colorPicker.current,
                colorKnob.current,
                hsl => setState({ hsl }),
                state.size
            )
        }
        return changeKnobEventListeners(false)
    }, [state.size])

    const getWheelDimensions = () => ({
        width: Math.floor(clamp(dimensions.width, 100, maxDim)),
        height: Math.floor(clamp(dimensions.width * 0.4, 100, maxDim))
    })

    useEffect(() => {
        updateColorCallback(state.hsl)
    }, [state.hsl])

    const updateColorCallback = (hsl: HSLColor) => {
        const { brightness } = state.color

        if ((typeof hsl.h || typeof hsl.s || typeof hsl.l) !== "number") return
        const hsv = hsl2hsv(hsl.h, hsl.s, hsl.l)
        const h = (hsv[0] + 360) / 360
        const s = hsv[1] / 100
        const b = brightness || 0.5
        updateDeviceColor(h, s, b)
    }

    const updateDeviceColor = (h: number, s: number, b: number) => {
        const rgb = hsvToRgb(h, s, b)
        setState({
            color: {
                hue: h,
                saturation: s,
                brightness: b
            }
        })
        props.onColorChange({
            r: rgb[0] as number,
            g: rgb[1] as number,
            b: rgb[2] as number
        })
    }

    const onBrightnessChange = (value: number | undefined) => {
        const { color } = state
        if (typeof value === "number") {
            updateDeviceColor(color.hue, color.saturation, value / 100)
        }
    }

    return (
        <Fragment>
            <div className={styles["color-picker-container"]} ref={container}>
                <div
                    className={styles["color-picker-wrapper"]}
                    ref={colorPicker}
                >
                    <div
                        style={
                            {
                                "--size": mql.matches ? "40vh" : "80vw"
                            } as CSSProperties
                        }
                        className={styles["color-wheel"]}
                        key={dimensions.height}
                    />
                    <div className={styles["knob"]} ref={colorKnob} />
                </div>
            </div>
            <div
                style={{
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    flex: 1
                }}
                onTouchMove={e => {
                    if (sliderRef.current && e.touches[0]) {
                        const { pageX } = e.touches[0]
                        const div = sliderRef.current
                        const { clientWidth } = div
                        const { left } = div.getBoundingClientRect()
                        const x = pageX - left
                        const brightness = (x / clientWidth) * 100
                        onBrightnessChange(brightness)
                    }
                }}
                ref={sliderRef}
            >
                <Slider
                    value={state.color.brightness * 100 || 0}
                    onChange={onBrightnessChange}
                    railStyle={{ height: "5px", borderRadius: "5px" }}
                    trackStyle={{ height: "5px", borderRadius: "5px" }}
                />
            </div>
        </Fragment>
    )
}

export { ColorWheel }
