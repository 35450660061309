import { i18nActionTypes } from "@store/action-types";
import produce from "immer";
const defaultState = {};

export default (state = defaultState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case i18nActionTypes.LOAD_LITERALS:
        return payload;
    }
  });
