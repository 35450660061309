import React from "react"
import IcoMoon from "react-icomoon"
import meshleIcons from "@lib/constants/icons.json"
type Props = {
    iconSet?: object // "selection.json file content"
    icon?: string // "home"
    size?: number | string // "1em", 10, "100px"
    color?: string // "red", "#f00", "rgb(0,0,0)"
    style?: object //	{...}	{ color: '#ff0'}
    className?: string // "icomoon"
    disableFill?: boolean // true
    removeInlineStyle?: boolean // true
}

const Icon = (props: Props) => {
    return <IcoMoon iconSet={meshleIcons} {...props} />
}

export { Icon }
