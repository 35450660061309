import React, { ComponentProps } from "react";
import { Form, Icon, Input, Checkbox } from "antd";
import { Button } from "@common/components/Button";
import theme from "@common/styles/defaultTheme";
import { Card } from "@common/components/_Card/Card";
import { FormComponentProps } from "antd/lib/form";
const styles = require("./form.module.less");
type Props = {} & ComponentProps<typeof Form> & FormComponentProps;

const MeshleRegisterForm = Form.create()((props: Props) => {
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const config = {
    rules: [{ type: "object", required: true, message: "Please select date!" }]
  };
  return (
    <Card size="lg">
      <Form onSubmit={handleSubmit} className={styles["register-form"]}>
        <Form.Item label="User Name">
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: theme["@primary"] }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your email!" }]
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: theme["@primary"] }} />}
              placeholder="example@example.com"
            />
          )}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: theme["@primary"] }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item label="Confirm Password">
          {getFieldDecorator("confirm-password", {
            rules: [{ required: true, message: "Please confir your assword!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: theme["@primary"] }} />}
              type="password"
              placeholder="Confirm Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: false
          })(
            <Checkbox>
              I agree to the <a className="link">Terms and Conditions</a>
            </Checkbox>
          )}
          <br />
          <a className="login-form-forgot" href="">
            Forgot Password?
          </a>
          <br />
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export { MeshleRegisterForm as RegisterForm };
