import { createStore, compose, applyMiddleware } from "redux";
import reducers from "@store/reducers";
import { rootSaga } from "@store/sagas";
import createSagaMiddleware from "@redux-saga/core";

const sagaMiddleWare = createSagaMiddleware();
const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
  compose;

export default createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleWare))
);

sagaMiddleWare.run(rootSaga);
