type Config = {
    env: "dev" | "prod"
}
export const dev: Config = {
    env: "dev"
}

export const prod: Config = {
    env: "prod"
}

export const config = process.env.NODE_ENV === "development" ? dev : prod
