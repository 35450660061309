import React, { useReducer, useEffect, CSSProperties, Fragment } from "react"
import { notification } from "antd"
import { selectDevice, blurApp } from "@store/action-creators"
import { rgbToColorString, hsvToRgb, hclToRgb } from "@lib/utils/color"
import { useDispatch, useSelector } from "react-redux"
import { DeviceSlider } from "@common/components/Slider"
import { getUserUid } from "@store/selectors"
import { Device, DeviceTypes } from "@lib/models"
import { RequestSetDeviceStatus } from "@store/saga-creators"
import { Modal } from "@common/components/Modal"
import { TypedReducer } from "@lib/utils"
import { DeviceModalContent } from "@common/components/Modal"
import { hasBrightness, isHSV, isHCL } from "@lib/guards"
import { Icon } from "@common/components/Icon"
import { mdiPower } from "@mdi/js"
import { FormattedMessage, useIntl } from "react-intl"

const styles = require("./card.module.less")

type Props = {
    title: string | JSX.Element
} & Device

type State = {
    showSettings: boolean
    hsb: {
        hue?: number
        saturation?: number
        brightness?: number
        whiteBalance?: number
    }
    currentColor: string
    modalVisible: boolean
}
const initState: State = {
    showSettings: false,
    hsb: { hue: 0, saturation: 0, brightness: 0 },
    currentColor: "rgb(255,255,255, 1)",
    modalVisible: false
}
const reducer: TypedReducer<State> = (state, property) => ({
    ...state,
    ...property
})

const DeviceCard = React.memo((props: Props) => {
    const [state, setState] = useReducer(reducer, initState)
    const dispatch = useDispatch()
    const userUid = useSelector(getUserUid)
    const intl = useIntl()
    useEffect(() => {
        setDeviceColor(props)
    }, [props])

    const setDeviceColor = (device: Device) => {
        if (isHSV(device)) {
            const { hue, saturation, brightness } = device
            const currentColor = getDeviceColor(device)
            setState({
                hsb: {
                    hue,
                    saturation,
                    brightness
                },
                currentColor
            })
        } else if (isHCL(device)) {
            const { brightness, whiteBalance } = device
            const currentColor = getDeviceColor(device)
            setState({
                hsb: {
                    whiteBalance,
                    brightness
                },
                currentColor
            })
        }
    }

    const getDeviceColor = (device: Device) => {
        let color = [255, 255, 255]
        if (isHSV(device)) {
            const { hue, saturation } = device
            color = hsvToRgb(hue, saturation, 1)
        } else if (isHCL(device)) {
            const { whiteBalance } = device
            color = hclToRgb(whiteBalance)
        }

        const red = Math.round(color[0])
        const green = Math.round(color[1])
        const blue = Math.round(color[2])
        return rgbToColorString(red, green, blue, 1)
    }
    const handleSetDeviceColor = () => {
        const { deviceId, deviceType, available } = props
        notification.close("power notification")
        if (!isHSV(props)) return
        if (!available) return showPowerDevicePrompt()
        const { hsb } = state
        dispatch(selectDevice(deviceId, hsb))
        // dispatch(blurApp(true))
        setState({ modalVisible: true })
        setState({ showSettings: true })
    }

    const showPowerDevicePrompt = () => {
        notification["info"]({
            message: intl.formatMessage({
                id: "app.notice.deviceDisconnected"
            }),
            description: intl.formatMessage({
                id: "app.notice.powerOnDevice"
            }),
            key: "power notification",
            style: {
                color: "#000"
            }
        })
    }
    const handleSetDeviceStatus = (
        event: React.MouseEvent | MouseEvent,
        value?: boolean
    ) => {
        event.stopPropagation()
        notification.close("power notification")
        const { isOn, deviceId, available, deviceType } = props
        if (!available) return showPowerDevicePrompt()
        const status = typeof value !== "undefined" ? value : !isOn
        dispatch(
            RequestSetDeviceStatus({ deviceId, status, userUid, deviceType })
        )
    }
    console.log("rendering device card")

    return (
        <React.Fragment>
            <div
                className={styles["device-card-body"]}
                style={
                    {
                        "--opacity": props.available ? 1 : 0.5
                    } as CSSProperties
                }
            >
                <DeviceSlider
                    canSwipe={hasBrightness(props)}
                    onClick={handleSetDeviceColor}
                    {...props}
                    color={state.currentColor}
                />
                <h1 className={styles["card-title"]}>
                    <span>{props.title}</span>
                </h1>
            </div>
            <div
                className={[
                    styles["card-actions"],
                    styles["device-card-actions"]
                ].join(" ")}
                style={
                    {
                        "--opacity": props.available ? 1 : 0.5
                    } as CSSProperties
                }
                onClick={e => handleSetDeviceStatus(e)}
            >
                <Icon icon="on-off" color="#fff" size="36px" />
            </div>
            <Modal
                title={props.title}
                visible={state.modalVisible}
                onClose={() => {
                    // dispatch(blurApp(false))
                    setState({ modalVisible: false })
                }}
                content={<DeviceModalContent />}
                hasActions={true}
                maskClosable
            />
        </React.Fragment>
    )
})

export default DeviceCard
