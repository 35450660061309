import React, { ComponentType } from "react";
import { PulseLoader } from "react-spinners";

const styles = require("./hocs.module.less");
type Props = {
  loading: boolean;
};
const WithLoading = <P extends object>(
  Component: ComponentType<P>
): React.FC<P & Props> => ({ loading, ...props }: Props) => {
  return loading ? (
    <div className={styles["loading-container"]}>
      <PulseLoader size={30} color="#fff" />
    </div>
  ) : (
      <Component {...(props as P)} />
    );
};

export { WithLoading };
