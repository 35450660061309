import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import { Card } from "@common/components/_Card/Card";
import { SectionHeader } from "@common/components/SectionHeader/SectionHeader";
import { Form } from "@common/components/Form";
import { Grid } from "@common/components/Grid/Grid";
import "./profile.style.less";
const loginTypes = require("./loginTypes.json");

const ProfileScreen = () => {
  const [registerType, setRegisterType] = useState({});

  const toggleShowRegister = registerType => {
    setRegisterType(registerType);
  };

  return (
    <div className="profile-container screen-container">
      <QueueAnim
        forcedReplay={true}
        delay={0}
        interval={100}
        animConfig={[
          { opacity: [1, 0], translateX: [0, 100] },
          { opacity: [1, 0], translateX: [0, -100] }
        ]}
      >
        <SectionHeader
          key="queue-anim-0"
          subtitle="login to have a personalised experience"
        />
        <Form key="queue-anim-1" className="login-form" />
        <SectionHeader
          key="queue-anim-2"
          subtitle="sign up with a method below"
        />
        {/* <Grid
          key="queue-anim-4"
          columnNum={3}
          data={loginTypes}
          onClick={el => toggleShowRegister(el)}
          renderItem={(item, index) =>
            !item ? (
              <div />
            ) : (
              <Link
                to={{
                  pathname: `modal/${item.link}`,
                  state: {
                    visible: true,
                    title: `Register with ${item.title}`,
                    modal: true
                  }
                }}
              >
                <Card
                  size={"sm"}
                  type="icon"
                  color={item.color}
                  id={item.id}
                  title={item.title}
                  icon={item.icon}
                  key={index}
                />
              </Link>
            )
          }
        /> */}
      </QueueAnim>
    </div>
  );
};

export default withRouter(ProfileScreen);
