import React, { ComponentProps } from "react";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
const styles = require("./form.module.less");
type Props = {
  type: string;
} & ComponentProps<any>;
const MeshleForm = (props: Props) => {
  const { type } = props;
  return (
    <div className={styles["form-container"]}>
      {type === "register" ? <RegisterForm /> : <LoginForm />}
    </div>
  );
};
export { MeshleForm as Form };
