import { Device, HSV, HCL, DeviceTypes, Dimmer } from "@lib/models"
import { isEmpty, has } from "lodash"
import {
    SetDeviceColorPayload,
    HSVColor,
    HCLColor,
    DimmerColor
} from "@store/models"

export const isHSV = (device: Device): device is HSV => {
    return !isEmpty(device) && device.deviceType === DeviceTypes.HSV
}
export const isHCL = (device: Device): device is HCL => {
    return !isEmpty(device) && device.deviceType === DeviceTypes.HCL
}
export const isDimmer = (device: Device): device is Dimmer => {
    return !isEmpty(device) && device.deviceType === DeviceTypes.DIMMER
}

export const hasBrightness = (device: Device): device is HSV | HCL | Dimmer => {
    return !isEmpty(device) && has(device, "brightness")
}

export const isHSVColor = (payload: any): payload is HSVColor => {
    return (
        !isEmpty(payload) &&
        has(payload, "saturation") &&
        has(payload, "hue") &&
        has(payload, "brightness")
    )
}

export const isHCLColor = (payload: any): payload is HCLColor => {
    return (
        !isEmpty(payload) &&
        has(payload, "whiteBalance") &&
        has(payload, "brightness")
    )
}
export const isDimmerColor = (payload: any): payload is DimmerColor => {
    return !isEmpty(payload) && has(payload, "brightness")
}

export const isSwitch = (device: Device) => {
    return !isEmpty(device) && device.deviceType === DeviceTypes.SWITCH
}
