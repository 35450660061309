import React, { useState, useEffect } from "react"
import {
    Switch,
    Route,
    withRouter,
    RouteComponentProps
} from "react-router-dom"
import {} from "react-router"
import Room from "@screens/Room/Room"
import Hotel from "@screens/Hotel/Hotel"
import Shop from "@screens/Shop/Shop"
import Profile from "@screens/Profile/Profile"
import { useSelector } from "react-redux"
import { getBlur, getMessagingObject } from "@store/selectors"
import { isEmpty } from "lodash"
import {
    error503_route,
    landing_route,
    hotel_route,
    shop_route,
    profile_route
} from "@lib/constants"
import { Error503 } from "@common/components/Errors"
import { getAuthService } from "@store/selectors"
import { WithLoading } from "@common/components/HOCs"
import { FormattedMessage } from "react-intl"
import { Banner } from "../Banner"
const styles = require("./navigator.module.less")

type Props = {} & RouteComponentProps<any>

const Navigator = WithLoading(
    withRouter((props: Props) => {
        const blur = useSelector(getBlur)
        const authService = useSelector(getAuthService)

        useEffect(() => {
            if (!authService.authenticated && !authService.authenticating) {
                props.history.push(error503_route)
            }
        }, [authService])

        const renderPage = () => {
            return (
                <div
                    className={styles["blur-container"]}
                    style={
                        blur
                            ? ({
                                  "--filter": "blur(5px) grayscale(50%)"
                              } as React.CSSProperties)
                            : ({ "--filter": "unset" } as React.CSSProperties)
                    }
                >
                    <Banner />

                    <Switch>
                        <Route path={landing_route} component={Room} exact />
                        <Route path={hotel_route} component={Hotel} />
                        <Route path={shop_route} component={Shop} />
                        <Route path={profile_route} component={Profile} />
                        <Route path={error503_route} component={Error503} />
                        <Route component={Error503} />
                    </Switch>
                    <p className={styles["footer"]}>
                        <FormattedMessage id="app.footer.caption" />
                        <span className={styles["version"]}>
                            version {process.env.REACT_APP_VERSION}
                        </span>
                    </p>
                </div>
            )
        }

        return (
            <div className={styles["navigator-container"]}>{renderPage()}</div>
        )
    })
)

export { Navigator }
