import React, { Component, ReactNode } from "react";
const styles = require("./sectionHeader.module.less");
type Props = {
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
  action?: ReactNode;
};
const SectionHeader = (props: Props) => {
  return (
    <div className={styles["section-header-container"]}>
      <h1 className={styles["section-title"]}>{props.title}</h1>
      {props.subtitle ? (
        <h2 className={styles["section-subtitle"]}>{props.subtitle}</h2>
      ) : (
        ""
      )}
      <div className={styles["section-action"]}>{props.action}</div>
    </div>
  );
};

export { SectionHeader };
