import React from 'react'

const Button = ({children, style, onClick}) => {
  const { buttonStyle } = styles
  return (
    <button onClick={onClick} style={ {...buttonStyle, ...style} }>{children}</button>
  )
}

const styles = {
  buttonStyle: {
    display: 'inline-block',
    width: 'auto',
    textDecoration:'none',
    padding: '15px',
    border: '1px solid grey',
    textAlign: 'center',
    fontSize: '18pt',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
}
export { Button };
