import React, { CSSProperties, useReducer, Fragment } from "react"
import { useSwipeable } from "react-swipeable"
import { useDispatch, useSelector } from "react-redux"
import { getUserUid } from "@store/selectors"
import { Touchable } from "@common/components/HOCs"
import { Device, HSV, HCL, Dimmer, Switch } from "@lib/models"
import { notification } from "antd"
import { RequestSetDeviceColor } from "@store/saga-creators"
import { Modal } from "@common/components/Modal"
import { TypedReducer } from "@lib/utils"
import { BrightnessSlider } from "./brightness-slider.component"
import { throttle } from "lodash"
import { isHSV, isHCL, isDimmer, hasBrightness } from "@lib/guards"
import { HSVColor, HCLColor, DimmerColor } from "@store/models"
const styles = require("./slider.module.less")

type Props = {
    isOn: boolean
    color: string
    title: string | JSX.Element
    canSwipe: boolean
    onClick: () => void
} & Device

type State = {
    showSlider: boolean
    percentage: number
    initialCords: number[]
}
const initState = {
    showSlider: false,
    percentage: 1,
    initialCords: [] as number[]
}
const reducer: TypedReducer<State> = (state, property) => ({
    ...state,
    ...property
})

const DeviceSlider = (props: Props) => {
    const [state, setState] = useReducer(reducer, initState)
    const dispatch = useDispatch()
    const userUid = useSelector(getUserUid)

    const showPowerDevicePrompt = () => {
        notification.close("power notification")
        notification["info"]({
            message: "Device Disconnected",
            description:
                "Please check to make sure that the device is connected to power",
            key: "power notification",
            style: {
                color: "#000"
            }
        })
    }
    const handlers = useSwipeable({
        trackMouse: props.canSwipe,
        preventDefaultTouchmoveEvent: false,
        trackTouch: props.canSwipe,
        delta: 50,
        onSwiping: e => {
            if (e.dir === "Up" || e.dir === "Down") return
            e.event.preventDefault()
            if (!props.available) return showPowerDevicePrompt()
            if (e.event instanceof MouseEvent) {
                if (e.event && e.event.clientX) {
                    setState({
                        percentage: Math.round(
                            Math.min(
                                Math.max(
                                    (e.event.clientX /
                                        (window.innerWidth * 0.9)) *
                                        100,
                                    0
                                ),
                                100
                            )
                        )
                    })
                }
            } else {
                if (
                    e.event &&
                    e.event.targetTouches[0] &&
                    e.event.targetTouches[0].clientX
                ) {
                    setState({
                        percentage: Math.round(
                            Math.min(
                                Math.max(
                                    (e.event.targetTouches[0].clientX /
                                        (window.innerWidth * 0.9)) *
                                        100,
                                    0
                                ),
                                100
                            )
                        )
                    })
                }
            }
            if (e.first) {
                setState({ showSlider: true, initialCords: e.initial })
                // dispatch(blurApp(true))
            }
            if (isHSV(props)) {
                dispatch(
                    RequestSetDeviceColor<HSVColor>({
                        deviceType: props.deviceType,
                        deviceId: props.deviceId,
                        color: {
                            hue: props.hue,
                            saturation: props.saturation,
                            brightness: state.percentage / 100
                        },
                        userUid
                    })
                )
            } else if (isHCL(props)) {
                dispatch(
                    RequestSetDeviceColor<HCLColor>({
                        deviceType: props.deviceType,
                        deviceId: props.deviceId,
                        color: {
                            whiteBalance: props.whiteBalance,
                            brightness: state.percentage / 100
                        },
                        userUid
                    })
                )
            } else if (isDimmer(props)) {
                dispatch(
                    RequestSetDeviceColor<DimmerColor>({
                        deviceType: props.deviceType,
                        deviceId: props.deviceId,
                        color: {
                            brightness: state.percentage / 100
                        },
                        userUid
                    })
                )
            }
        },
        onSwiped: e => {
            if (e.dir === "Up" || e.dir === "Down")
                return setState({ showSlider: false })
            e.event.preventDefault()
            if (!props.available) showPowerDevicePrompt()
            setState({ showSlider: false })
            // dispatch(blurApp(false))
        }
    })

    const getBrightness = () => {
        if (hasBrightness(props)) return props.brightness * 100
        else return props.isOn ? 100 : 0
    }

    return (
        <div onClick={props.onClick}>
            <div className={styles["device-slider-touch-area"]} {...handlers} />
            <div
                className={styles["device-slider-bg-color"]}
                style={
                    !props.isOn
                        ? ({
                              "--border-size": "1px",
                              "--width": "0px"
                          } as CSSProperties)
                        : ({
                              "--background-color": props.color,
                              "--width": getBrightness() + "%"
                          } as CSSProperties)
                }
            ></div>
            <Modal
                onClose={() => {}}
                visible={state.showSlider}
                title={props.title}
                content={
                    <BrightnessSlider
                        visible={state.showSlider}
                        startCords={state.initialCords}
                        color={props.color}
                        value={state.percentage}
                    />
                }
            />
        </div>
    )
}
export { DeviceSlider }
