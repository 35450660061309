import React, { Component, CSSProperties } from "react"
import PropTypes from "prop-types"
import { Card } from "antd"
import DeviceCard from "./DeviceCard"
import { IconCard } from "./IconCard"
import ThemeCard from "./ThemeCard"
import { WingBlank } from "antd-mobile"
import { Device, Theme } from "@lib/models"
import theme from "@common/styles/defaultTheme"
import { KeyOfObject } from "@lib/utils"

const style = require("./card.module.less")
const defaultProps = {
    type: "normal",
    icon: "bulb",
    id: 0,
    title: "",
    color: "#ddd"
}
type Props = {
    type: string
    icon: string
    id: number
    title: string | JSX.Element
    color: KeyOfObject<typeof theme>
    device?: Device
    theme?: Theme
    size?: string
    children?: React.ReactNode
}

const MeshleCard = (props: Props) => {
    const renderDeviceContent = () => {
        const { device, title } = props

        if (!device) return <div />
        return <DeviceCard title={title} {...device} />
    }
    const renderIconCard = () => {
        const { color, icon, title } = props
        return <IconCard color={color} icon={icon} title={title as any} />
    }
    const renderThemeCard = () => {
        const { theme, id } = props
        if (!theme) return <div />
        return <ThemeCard id={id} {...theme} />
    }
    return (
        <React.Fragment>
            <WingBlank style={{ height: "100%" }} size="sm">
                <Card
                    className={
                        [
                            style["card-container"],
                            style[`${props.type}-card`],
                            style[`card-container-${props.size}`]
                        ] as any
                    }
                >
                    {props.type === "normal" ? props.children : ""}
                    {props.type === "device" ? renderDeviceContent() : ""}
                    {props.type === "icon" ? renderIconCard() : ""}
                    {props.type === "theme" ? renderThemeCard() : ""}
                </Card>
            </WingBlank>
        </React.Fragment>
    )
}
MeshleCard.defaultProps = defaultProps

export { MeshleCard as Card }
