import React, { Component } from "react"
import QueueAnim from "rc-queue-anim"
import { Card } from "@common/components/_Card"
import { SectionHeader } from "@common/components/SectionHeader"
import { Carousel } from "@common/components/Carousel"
import "./shop.style.less"

const ShopScreen = () => {
    return (
        <div className="shop-container screen-container">
            <QueueAnim
                forcedReplay={true}
                delay={0}
                interval={100}
                animConfig={[
                    { opacity: [1, 0], translateX: [0, 100] },
                    { opacity: [1, 0], translateX: [0, -100] }
                ]}
            >
                <SectionHeader
                    title="Our Shop"
                    key="queue-anim-0"
                    subtitle="Top products in store"
                />
                <Card size={"lg"} title={"Classic"} key="queue-anim-1" />
                <SectionHeader
                    title="Wine"
                    key="queue-anim-2"
                    action={<a className="link">see all</a>}
                />
                <Carousel key="queue-anim-3">
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                </Carousel>
                <SectionHeader
                    key="queue-anim-4"
                    title="Soveniers"
                    action={<a className="link">see all</a>}
                />
                <Carousel key="queue-anim-5">
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                    <Card size={"sm"} />
                </Carousel>
            </QueueAnim>
        </div>
    )
}
export default ShopScreen
