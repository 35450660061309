import {
  MainActionTypes,
  BLUR_APP,
  SET_WINDOW_DIMENSIONS,
  SET_USER_UNIQUE_ID
} from "@store/action-types";
import produce from "immer";
export type MainState = {
  blur: boolean;
  windowDimensions: { width: number; height: number };
  userUid: string;
};
const initState: MainState = {
  blur: false,
  windowDimensions: { width: 0, height: 0 },
  userUid: ''
};

export default (state = initState, action: MainActionTypes) =>
  produce(state, draft => {
    switch (action.type) {
      case BLUR_APP:
        draft.blur = action.blur;
        return draft;
      case SET_WINDOW_DIMENSIONS:
        draft.windowDimensions = action.payload;
        return draft;
      case SET_USER_UNIQUE_ID:
        draft.userUid = action.payload;
        return draft;
    }
  });
