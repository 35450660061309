import React, { ReactNode, useRef, CSSProperties, ComponentProps } from "react"
import { Modal } from "antd-mobile"
import { Button } from "antd"
import NavBar from "@common/components/NavBar/NavBar"
import { useSelector } from "react-redux"
import { getWindowDimensions } from "@store/selectors"
import { FormattedMessage } from "react-intl"
import { ModalProps } from "antd-mobile/lib/modal/Modal"
import { Icon } from "../Icon"
const styles = require("./modal.module.less")

type Props = {
    visible: boolean
    title: string | JSX.Element
    onClose: () => void
    hasActions?: boolean
    content: ReactNode
    maskClosable?: boolean
    onShow?: () => void
} & ModalProps

const mql = window.matchMedia("(orientation: landscape)")

const MeshleModal = (props: Props) => {
    const modalContent = useRef<Modal>(null)
    const dimensions = useSelector(getWindowDimensions)
    return (
        <Modal
            {...props}
            ref={modalContent}
            className={styles["modal-container"]}
            visible={props.visible}
            onShow={props.onShow}
            transparent
            animated
            animationType={"fade"}
            onClose={props.onClose}
            maskClosable={props.maskClosable}
            style={{ "--view-height": window.innerHeight } as CSSProperties}
            title=""
        >
            <div className={styles["modal-header"]}>
                <div className={styles["modal-title"]}>{props.title}</div>
                <Button
                    style={{ right: 0 }}
                    className={styles["button"]}
                    shape="round"
                    color="#fff"
                    onClick={props.onClose}
                >
                    <Icon icon={"close"} size="calc(16px + 1vw)" />
                </Button>
            </div>
            <div
                className={styles["modal-content"]}
                style={
                    {
                        "--place-self":
                            mql.matches && dimensions.height < 600
                                ? "start"
                                : "center"
                    } as CSSProperties
                }
            >
                {props.content}
            </div>
            {props.hasActions && (
                <div
                    className={styles["modal-actions"]}
                    onClick={props.onClose}
                >
                    <span>
                        <FormattedMessage id="app.text.close" />
                    </span>
                </div>
            )}
        </Modal>
    )
}

export { MeshleModal as Modal }
