export interface AbstractDevice {
    available: boolean
    deviceId: number
    deviceName: string
    deviceType: DeviceTypes
}

export interface Switch extends AbstractDevice {
    deviceType: DeviceTypes.SWITCH
    isOn: boolean
}

export interface HSV extends AbstractDevice {
    isOn: boolean
    deviceType: DeviceTypes.HSV
    saturation: number
    hue: number
    brightness: number
}

export interface HCL extends AbstractDevice {
    isOn: boolean
    whiteBalance: number
    brightness: number
    deviceType: DeviceTypes.HCL
}

export interface Dimmer extends AbstractDevice {
    isOn: boolean
    deviceType: DeviceTypes.DIMMER
    brightness: number
}

export enum DeviceTypes {
    HSV = 1, //"RGB", RGBW,
    SWITCH = 2, //"SWITCH"
    // RGBW = 3, //"RGBW",
    HCL = 3, //"WARM_COLD",
    DIMMER = 4, //"DIMMABLE_WHITE",
    LUCTRA = 5,
    GATEWAY = 0x0a, //"DIMMABLE_WHITE",
    ANY = 0xff
}

export type Device = HSV | HCL | Dimmer | Switch
