/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
const rgbToHsl = (red, green, blue) => {
    var r = red / 255
    var g = green / 255
    var b = blue / 255

    var max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    var h,
        s,
        l = (max + min) / 2

    if (max === min) {
        h = s = 0 // achromatic
    } else {
        var d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
            default:
        }

        h /= 6
    }

    return [h, s, l]
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
const hslToRgb = (h, s, l) => {
    var r, g, b

    if (s === 0) {
        r = g = b = l // achromatic
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s
        var p = 2 * l - q

        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }

    return [r * 255, g * 255, b * 255]
}

/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSV representation
 */
const rgbToHsv = (red, green, blue) => {
    var r = red / 255
    var g = green / 255
    var b = blue / 255

    var max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    var h,
        s,
        v = max

    var d = max - min
    s = max === 0 ? 0 : d / max

    if (max === min) {
        h = 0 // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
            default:
        }

        h /= 6
    }

    return [h, s, v]
}

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  v       The value
 * @return  Array           The RGB representation
 */
const hsvToRgb = (h, s, v) => {
    var r, g, b

    var i = Math.floor(h * 6)
    var f = h * 6 - i
    var p = v * (1 - s)
    var q = v * (1 - f * s)
    var t = v * (1 - (1 - f) * s)

    switch (i % 6) {
        case 0:
            r = v
            g = t
            b = p
            break
        case 1:
            r = q
            g = v
            b = p
            break
        case 2:
            r = p
            g = v
            b = t
            break
        case 3:
            r = p
            g = q
            b = v
            break
        case 4:
            r = t
            g = p
            b = v
            break
        case 5:
            r = v
            g = p
            b = q
            break
        default:
    }

    return [r * 255, g * 255, b * 255]
}

const rgbToColorString = (red, green, blue, opacity) => {
    return `rgba(${red},${green},${blue}, ${opacity})`
}

const rgbToHex = rgb => {
    let hex = Number(rgb).toString(16)
    if (hex.length < 2) {
        hex = "0" + hex
    }
    return hex
}
const rgbToHexString = (red, green, blue) => {
    var red = rgbToHex(red)
    var green = rgbToHex(green)
    var blue = rgbToHex(blue)
    return `#${red + green + blue}`
}
const rgbStringToObject = rgb => {
    const colorArr = rgb.replace(/[^\d,]/g, "").split(",")

    const color = rgbToHsv(
        Number(colorArr[0]),
        Number(colorArr[1]),
        Number(colorArr[2])
    )
    return {
        hue: color[0] ? color[0] : 0,
        saturation: color[1] ? color[1] : 0,
        brightness: color[2] ? color[2] : 1
    }
}

function hexToRGBString(h) {
    let r = 0,
        g = 0,
        b = 0

    // 3 digits
    if (h.length == 4) {
        r = "0x" + h[1] + h[1]
        g = "0x" + h[2] + h[2]
        b = "0x" + h[3] + h[3]

        // 6 digits
    } else if (h.length == 7) {
        r = "0x" + h[1] + h[2]
        g = "0x" + h[3] + h[4]
        b = "0x" + h[5] + h[6]
    }

    return "rgb(" + +r + "," + +g + "," + +b + ")"
}
function hexToRGB(h) {
    let r = 0,
        g = 0,
        b = 0

    // 3 digits
    if (h.length == 4) {
        r = "0x" + h[1] + h[1]
        g = "0x" + h[2] + h[2]
        b = "0x" + h[3] + h[3]

        // 6 digits
    } else if (h.length == 7) {
        r = "0x" + h[1] + h[2]
        g = "0x" + h[3] + h[4]
        b = "0x" + h[5] + h[6]
    }

    return [r, g, b]
}
/**
 * @func hsv2hsl
 * @desc Return an HSL color from an HSV color
 * @param {Number} h - Hue Angle (0 - 360)
 * @param {Number} s - Saturation (0 - 100)
 * @param {Number} v - Value (0 - 100)
 * @return {ArrayHSL}
 * @example
 * hsv2hsl(0, 0, 0) // => [0, 100, 50]
 * @link https://gist.github.com/defims/0ca2ef8832833186ed396a2f8a204117
 */
function hsv2hsl(hsvH, hsvS, hsvV) {
    const hslL = ((200 - hsvS) * hsvV) / 100
    const [hslS, hslV] = [
        hslL === 0 || hslL === 200
            ? 0
            : ((hsvS * hsvV) / 100 / (hslL <= 100 ? hslL : 200 - hslL)) * 100,
        (hslL * 5) / 10
    ]
    return [hsvH, hslS, hslV]
}

/**
 * @func hsl2hsv
 * @desc Return an HSV color from an HSL color
 * @param {Number} h - Hue Angle (0 - 360)
 * @param {Number} s - Saturation (0 - 100)
 * @param {Number} l - Lightness (0 - 100)
 * @return {ArrayHSV}
 * @example
 * hsl2hsv(0, 100, 50)
 * @link https://gist.github.com/defims/0ca2ef8832833186ed396a2f8a204117
 */
function hsl2hsv(hslH, hslS, hslL) {
    const hsv1 = (hslS * (hslL < 50 ? hslL : 100 - hslL)) / 100
    const hsvS = hsv1 === 0 ? 0 : ((2 * hsv1) / (hslL + hsv1)) * 100
    const hsvV = hslL + hsv1
    return [hslH, hsvS, hsvV]
}

/**
 * Converts white balance value used by cct device to rgb values
 * 🚀
 * @param whiteBalance White Balance value between 0 and 100
 */
const hclToRgb = whiteBalance => {
    var r, g, b
    whiteBalance = 34 + (whiteBalance / 100) * (100 - 34)
    if (whiteBalance <= 66) {
        r = 255
        g = Math.min(
            Math.max(
                99.4708025861 * Math.log(whiteBalance) - 161.1195681661,
                0
            ),
            255
        )
    } else {
        r = Math.min(
            Math.max(
                329.698727446 * Math.pow(whiteBalance - 60, -0.1332047592),
                0
            ),
            255
        )
        g = Math.min(
            Math.max(
                288.1221695283 * Math.pow(whiteBalance - 60, -0.0755148492),
                0
            ),
            255
        )
    }

    if (whiteBalance >= 66) {
        b = 255
    } else if (whiteBalance <= 19) {
        b = 0
    } else {
        b = whiteBalance - 10
        b = Math.min(
            Math.max(138.5177312231 * Math.log(b) - 305.0447927307, 0),
            255
        )
    }

    return [Math.round(r), Math.round(g), Math.round(b)]
}

export {
    rgbToHsl,
    hslToRgb,
    rgbToHsv,
    hsvToRgb,
    rgbToColorString,
    rgbToHexString,
    rgbStringToObject,
    hexToRGB,
    hexToRGBString,
    hsl2hsv,
    hsv2hsl,
    hclToRgb
}
