import {
  ADD_MESSAGING_SERVICE,
  CHANGE_AUTH_STATE,
  ADD_INTERNATIONALIZATION_SERVICE
} from "@store/action-types";
import {
  AddMessagingServicePayload,
  ChangeAuthStatePayload,
  AddInternalizationServicePayload
} from "@store/models";

export const AddMessagingService = (payload: AddMessagingServicePayload) => ({
  type: ADD_MESSAGING_SERVICE,
  payload
});
export const ChangeAuthState = (payload: ChangeAuthStatePayload) => ({
  type: CHANGE_AUTH_STATE,
  payload
});
export const AddInternalizationService = (
  payload: AddInternalizationServicePayload
) => ({
  type: ADD_INTERNATIONALIZATION_SERVICE,
  payload
});
