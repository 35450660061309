import {
  SET_DEVICE_COLOR,
  SET_DEVICE_STATUS,
  GET_AVAILABLE_DEVICES,
  ADD_MESSAGING_OBJECT,
  FETCH_AVAILABLE_DEVICES,
  SELECT_DEVICE,
  DESELECT_DEVICE,
  DEVICE_LOST
} from "@store/action-types";
import { SetDeviceColorPayload, SetDeviceStatusPayload, GetAvailableDevicesPayload } from "@store/models";

export const getAvailableDevices = (payload: GetAvailableDevicesPayload) => {
  return {
    type: GET_AVAILABLE_DEVICES,
    payload
  };
};

export const setDeviceColor = (payload: SetDeviceColorPayload) => {
  return {
    type: SET_DEVICE_COLOR,
    payload
  };
};
export const setDeviceStatus = (payload: SetDeviceStatusPayload) => {
  return {
    type: SET_DEVICE_STATUS,
    payload
  };
};

export const addMessagingObject = messagingObject => ({
  type: ADD_MESSAGING_OBJECT,
  payload: messagingObject
});

export const fetchAvailableDevices = devices => {
  return {
    type: FETCH_AVAILABLE_DEVICES,
    payload: devices
  };
};

export const deviceLost = payload => ({
  type: DEVICE_LOST,
  payload
})

export const selectDevice = (deviceId, hsb) => {
  return {
    type: SELECT_DEVICE,
    payload: { deviceId, hsb }
  };
};

export const deSelectDevice = () => {
  return {
    type: DESELECT_DEVICE
  };
};
