import React, { useState, useEffect, CSSProperties } from "react"
import Icon from "@mdi/react"
import { ReactComponent as Google } from "@common/img/google.svg"
import { Icon as CustomIcon } from "@common/components/Icon"
import theme from "@common/styles/defaultTheme"
import { KeyOfObject } from "@lib/utils"
import customIcons from "@lib/constants/icons.json"
const styles = require("./card.module.less")

type Props = {
    color?: KeyOfObject<typeof theme>
    title?: JSX.Element | string
    handleclick?: () => void
    shape?: "round" | "flat" | "curved"
    icon: string
    type?: "custom" | "mdi" | "svg"
    size?: number | string
    background?: KeyOfObject<typeof theme>
    borderColor?: KeyOfObject<typeof theme>
}

export const IconCard = (props: Props) => {
    const { color, icon, title, shape, type } = props
    const [borderRadius, setBorderRadius] = useState("0px")

    useEffect(() => {
        getBorderRadius()
    }, [shape])

    const getBorderRadius = () => {
        switch (shape) {
            case "round":
                setBorderRadius(theme["@border-radius-round"])
                break
            case "curved":
                setBorderRadius(theme["@border-radius-lg"])
                break
            default:
                setBorderRadius("0px")
                break
        }
    }

    const renderIcon = () => {
        switch (type) {
            case "svg":
                return <Google />
            case "custom":
                return <CustomIcon iconSet={customIcons} {...props} />
            default:
                return (
                    <Icon path={icon} color={theme[color]} size={props.size} />
                )
        }
    }

    return (
        <div
            className={styles["icon-card"]}
            style={
                {
                    "--border-radius": borderRadius,
                    "--background": theme[props.background] || "transparent",
                    "--border": `1px solid ${theme[props.borderColor] ||
                        "transparent"}`,
                    "--height": props.size
                } as CSSProperties
            }
        >
            {icon && (
                <div
                    className={styles["icon-card-icon"]}
                    onClick={props.handleclick}
                >
                    {renderIcon()}
                </div>
            )}
            {title && <h1 className={styles["icon-card-title"]}>{title}</h1>}
        </div>
    )
}
