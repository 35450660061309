import React, { ReactNode, CSSProperties } from "react";
import { InferType } from "prop-types";
const styles = require('./grid.module.less');

type Props= {
  items: any[];
  minItemSize: number;
  renderItem: (item: InferType<Props['items']>, index: number) => ReactNode;
  style?: CSSProperties
}

const MeshleGrid: React.FC<Props> = props => {
  return (
    <div className={styles['grid-container']} style={{...props.style, ...{'--min-size': props.minItemSize+"px"}} as CSSProperties}>
      {
        props.items.map((item, i) => (
          <div className={styles['grid-item']} key={i}>
            { props.renderItem(item, i) }
          </div>
        ))
      }    
    </div>  
  );
};

export { MeshleGrid as Grid };
