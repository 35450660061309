import { createSelector } from "reselect";
import { AppState, Device } from "@lib/models";
import { getDevices } from "./device.selectors";
import { HSVColor } from "@store/models";

const getSelection = (state: AppState) => state.selection;

export const getSelectedId = createSelector([getSelection], selection =>
  selection ? selection.deviceId : false
);
export const getSelectedDevice = createSelector(
  [getDevices, getSelectedId],
  (devices, deviceId) =>
    devices && deviceId ? devices[`1${deviceId}`] : ({} as Device)
);
export const getSelectedDeviceColor = createSelector(
  [getSelection],
  selection =>
    selection && selection.color ? selection.color : ({} as HSVColor)
);
