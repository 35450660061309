import React, { Component } from "react"
import QueueAnim from "rc-queue-anim"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

import { Card } from "@common/components/_Card"
import { SectionHeader } from "@common/components/SectionHeader/SectionHeader"
import "./hotel.style.less"
import { Carousel } from "@common/components/Carousel"

const HotelScreen = () => {
    return (
        <div className="hotel-container screen-container">
            <QueueAnim
                forcedReplay={true}
                delay={0}
                interval={100}
                leaveReverse={true}
                animConfig={[
                    { opacity: [1, 0], translateX: [0, 100] },
                    { opacity: [1, 0], translateX: [0, -100] }
                ]}
            >
                <SectionHeader
                    key="queue-anim-0"
                    title={<FormattedMessage id="app.about-us.title" />}
                    subtitle={<FormattedMessage id="app.about-us.subtitle" />}
                    action={<FormattedHTMLMessage id="app.more.link" />}
                />
                <Card size={"lg"} key="queue-anim-1" />
                <SectionHeader
                    key="queue-anim-2"
                    title="Events"
                    subtitle="All our events at a glance"
                    action={<FormattedHTMLMessage id="app.see-all.link" />}
                />
                <Carousel key="queue-anim-3">
                    <Card size={"md"} />
                    <Card size={"md"} />
                    <Card size={"md"} />
                    <Card size={"md"} />
                    <Card size={"md"} />
                    <Card size={"md"} />
                </Carousel>
                <SectionHeader
                    key="queue-anim-4"
                    title="Bar/Restaurant"
                    subtitle="Our current menu of the day"
                    action={<FormattedHTMLMessage id="app.see-all.link" />}
                />
                <Carousel key="queue-anim-5">
                    <Card size={"lg"} />
                    <Card size={"lg"} />
                    <Card size={"lg"} />
                    <Card size={"lg"} />
                    <Card size={"lg"} />
                    <Card size={"lg"} />
                </Carousel>
            </QueueAnim>
        </div>
    )
}
export default HotelScreen
