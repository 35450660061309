import { createSelector } from "reselect";
import { AppState } from "@lib/models/index";
import { MessagingObject } from "@store/reducers/device.reducer";

const getDevicesState = (state: AppState) => state.devices;

export const getDevices = createSelector(
  [getDevicesState],
  devicesState => (devicesState ? devicesState.devices : {})
);
export const getMessagingObject = createSelector(
  [getDevicesState],
  devicesState =>
    devicesState ? devicesState.messagingObject : ({} as MessagingObject)
);
