import {
    DeviceActionTypes,
    ADD_MESSAGING_OBJECT,
    FETCH_AVAILABLE_DEVICES,
    DEVICE_LOST
} from "@store/action-types"
import { produce } from "immer"
import { Device } from "@lib/models"
import { Service } from "@feathersjs/feathers"

export const deriveDeviceUid = (data: {
    deviceType: number
    deviceId: number
}) => `${data.deviceType}${data.deviceId}`

export type MessagingObject = {
    token: string
    gatewayID: string
    dataMessagesService: Service<any>
}

export type DevicesState = {
    ignoreUpdates: boolean
    devices: { [key: number]: Device }
    messagingObject: MessagingObject
}
const init_state: DevicesState = {
    devices: {},
    messagingObject: {} as MessagingObject,
    ignoreUpdates: false
}

export default (state = init_state, action: DeviceActionTypes) =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_MESSAGING_OBJECT: {
                let messagingObject = {} as MessagingObject
                const { payload } = action
                if (payload) {
                    messagingObject = payload
                }
                draft.messagingObject = messagingObject
                return draft
            }
            case FETCH_AVAILABLE_DEVICES: {
                const { payload } = action
                if (payload !== undefined && !draft.ignoreUpdates) {
                    console.log(payload, "MESSAGES")

                    payload.forEach(message => {
                        const { msgType, data } = message
                        console.log("messageType", data, msgType)

                        switch (msgType) {
                            case "status": {
                                const deviceUid = deriveDeviceUid({ ...data })
                                if (data.deviceId < 0x80) {
                                    // exclude groups and broadcast
                                    draft.devices[deviceUid] = {
                                        ...data,
                                        available: true
                                    }
                                }
                                break
                            }
                        }
                    })
                }
                return draft
            }

            case DEVICE_LOST: {
                const { payload } = action
                const { devices } = draft
                if (payload !== undefined) {
                    console.log("deviceLost", payload)
                    const deviceUid = deriveDeviceUid({ ...payload })
                    const device: Device = devices[deviceUid]

                    if (Object.keys(devices).includes(deviceUid)) {
                        devices[deviceUid] = {
                            ...device,
                            available: false
                        }
                    }
                }
                draft.devices = devices
                return draft
            }
        }
    })
