import { combineReducers } from "redux";
import devicesReducer from "./device.reducer";
import selectionReducer from "./selection.reducer";
import i18nReducer from "./i18n.reducer";
import mainReducer from "./main.reducer";
import servicesReducer from "./services.reducer";

export default combineReducers({
  devices: devicesReducer,
  services: servicesReducer,
  selection: selectionReducer,
  i18n: i18nReducer,
  main: mainReducer
});
