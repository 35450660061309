import React, { ComponentProps } from "react";
import { Form, Icon, Input, Checkbox } from "antd";
import { Card } from "@common/components/_Card/Card";
import { Button } from "@common/components/Button";
import theme from "@common/styles/defaultTheme";
import { FormComponentProps } from "antd/lib/form";
const styles = require("./form.module.less");
type Props = {} & ComponentProps<typeof Form> & FormComponentProps;

const MeshleLoginForm = Form.create()((props: Props) => {
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <Card size="lg">
      <Form onSubmit={handleSubmit} className={styles["login-form"]}>
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: theme["@primary"] }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: theme["@primary"] }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox>Remember Me</Checkbox>)}
          <br />
          <a href="">Forgot Password</a>
          <br />
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export { MeshleLoginForm as LoginForm };
