import {
    ADD_MESSAGING_SERVICE,
    ServiceActionTypes,
    CHANGE_AUTH_STATE,
    ADD_INTERNATIONALIZATION_SERVICE
} from "@store/action-types"
import { produce } from "immer"
import { Service } from "@feathersjs/feathers"

export type ServicesState = {
    messagingService: Service<any>
    authService: {
        authenticating: boolean
        authenticated: boolean
    }
    internalizationService: Service<any>
}

const init_state: ServicesState = {
    messagingService: {} as Service<any>,
    authService: {
        authenticating: true,
        authenticated: false
    },
    internalizationService: {} as Service<any>
}

export default (state = init_state, action: ServiceActionTypes) =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_MESSAGING_SERVICE: {
                const { messagingService } = action.payload
                draft.messagingService = messagingService
                return draft
            }
            case CHANGE_AUTH_STATE: {
                draft.authService = {
                    ...draft.authService,
                    ...action.payload
                    // authenticated: true // TODO: remove this line for production (add env variables to make this configurable from terminal)
                }
                return draft
            }
            case ADD_INTERNATIONALIZATION_SERVICE: {
                draft.internalizationService =
                    action.payload.internalizationService
                return draft
            }
        }
    })
