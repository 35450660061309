import { throttle, takeEvery, select, call } from "redux-saga/effects"
import {
    REQUEST_SET_DEVICE_STATUS,
    REQUEST_SET_DEVICE_COLOR,
    REQUEST_GET_AVAILABLE_DEVICES
} from "@store/saga-types"

import {
    RequestSetDeviceStatus,
    RequestSetDeviceColor,
    RequestGetAvailableDevices
} from "@store/saga-creators"
import { getUserUid, getMessagingObject } from "@store/selectors"
import { AVAILABLE_DEVICES } from "@lib/constants/messageTypes"
import { isEmpty } from "lodash"
import { isHSVColor, isHCLColor, isDimmerColor } from "@lib/guards"

export type SendEventPayload = {
    deviceType: number
    deviceId: number
    status?: boolean
    userUid: string
    hue?: number
    saturation?: number
    brightness?: number
    duration: number
    maxDevice?: number
    startIndex?: number
}
export function* sendEvent(
    eventName: string,
    payload: SendEventPayload,
    trial = 0
) {
    try {
        const messagingObject = yield select(getMessagingObject)
        const userUid = yield select(getUserUid)
        // console.log({ name: "PAID", payload, messagingObject, userUid })
        if (isEmpty(messagingObject)) throw new Error("No messaging object")
        else if (!userUid && trial <= 3)
            yield sendEvent(eventName, payload, trial + 1)
        else {
            const { dataMessagesService, token, gatewayID } = messagingObject
            const data = {
                token,
                gatewayID,
                event: eventName,
                payload,
                userUid
            }
            const message = yield dataMessagesService.create(data)
            console.warn(
                `>>>event successfuly created: ${JSON.stringify(message)}`
            )
            return Promise.resolve(true)
        }
    } catch (e) {
        console.error(`>>>event creation failed: ${JSON.stringify(e)}`)
        return Promise.reject(false)
    }
}

export function* setDeviceStatusFlow() {
    yield throttle<ReturnType<typeof RequestSetDeviceStatus>>(
        250,
        REQUEST_SET_DEVICE_STATUS,
        function*({ payload }) {
            try {
                // yield put(setDeviceStatus(payload))
                yield call<any>(sendEvent, "setStatus", {
                    deviceType: payload.deviceType,
                    deviceId: payload.deviceId,
                    status: payload.status,
                    userUid: payload.userUid,
                    duration: 10
                })
            } catch (error) {}
        }
    )
}

export function* setDeviceColorFlow() {
    yield throttle<ReturnType<typeof RequestSetDeviceColor>>(
        250,
        REQUEST_SET_DEVICE_COLOR,
        function*({ payload }) {
            try {
                if (isHSVColor(payload.color)) {
                    yield call<any>(sendEvent, "setColor", {
                        deviceType: payload.deviceType,
                        deviceId: payload.deviceId,
                        hue: payload.color.hue,
                        saturation: payload.color.saturation,
                        brightness: payload.color.brightness,
                        userUid: payload.userUid,
                        duration: 10
                    })
                } else if (isHCLColor(payload.color)) {
                    yield call<any>(sendEvent, "setCCTColor", {
                        deviceType: payload.deviceType,
                        deviceId: payload.deviceId,
                        whiteBalance: payload.color.whiteBalance,
                        brightness: payload.color.brightness,
                        userUid: payload.userUid,
                        duration: 10
                    })
                } else if (isDimmerColor(payload.color)) {
                    yield call<any>(sendEvent, "setDimmerColor", {
                        deviceType: payload.deviceType,
                        deviceId: payload.deviceId,
                        brightness: payload.color.brightness,
                        userUid: payload.userUid,
                        duration: 10
                    })
                }
            } catch (error) {}
        }
    )
}

export function* getAvailableDevicesFlow() {
    yield takeEvery<ReturnType<typeof RequestGetAvailableDevices>>(
        REQUEST_GET_AVAILABLE_DEVICES,
        function*({ payload }) {
            try {
                console.log("getavaaaaaaaal..........")
                yield call<any>(sendEvent, AVAILABLE_DEVICES, {
                    maxDevice: 255,
                    startIndex: 0
                })
            } catch (error) {
                console.log("getAvailableDevicesFlow", error)
            }
        }
    )
}
