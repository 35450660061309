import React, { FC } from "react"
import packageJson from "../package.json"
import { config } from "@lib/constants/config"
global.appVersion = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())

        const b = Number(versionsB.shift())
        // eslint-disable-next-line no-continue
        if (a === b) continue
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b)
    }
    return false
}

type Props = {
    children: FC<State>
}

type State = {
    loading: boolean
    isLatestVersion: boolean
    refreshCacheAndReload: () => void
}
class CacheBuster extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log("Clearing cache and hard reloading...")
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name)
                    })
                }

                // delete browser cache and hard reload
                window.location.reload(true)
            }
        }
    }

    componentDidMount() {
        if (config.env === "prod") {
            fetch(`${process.env.PUBLIC_URL}/meta.json`)
                .then(response => response.json())
                .then(meta => {
                    const latestVersion = meta.version
                    const currentVersion = global.appVersion

                    const shouldForceRefresh = semverGreaterThan(
                        latestVersion,
                        currentVersion
                    )
                    if (shouldForceRefresh) {
                        console.log(
                            `We have a new version - ${latestVersion}. Should force refresh`
                        )
                        this.setState({
                            loading: false,
                            isLatestVersion: false
                        })
                    } else {
                        console.log(
                            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
                        )
                        this.setState({ loading: false, isLatestVersion: true })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, isLatestVersion: true })
                    console.log(err)
                })
        } else {
            this.setState({ loading: false, isLatestVersion: true })
        }
    }
    render() {
        const { children } = this.props
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state
        return children({ loading, isLatestVersion, refreshCacheAndReload })
    }
}

export default CacheBuster
