export const themes = [
    {
        colors: [
            [0, 0, 60],
            [0, 0, 50],
            [0, 0, 60],
            [0, 0, 50]
        ],
        name: "classic"
    },
    {
        colors: [
            [196, 100, 0],
            [157, 100, 0],
            [252, 100, 0],
            [302, 80, 0]
        ],
        name: "northernlights"
    },
    {
        colors: [
            [0, 100, 0],
            [282, 100, 0],
            [295, 100, 0],
            [0, 100, 0]
        ],
        name: "romantic"
    },
    {
        colors: [
            [247, 100, 0],
            [200, 100, 0],
            [232, 100, 0],
            [173, 100, 0]
        ],
        name: "oceanblue"
    },
    {
        colors: [
            [24, 100, 0],
            [45, 100, 0],
            [20, 100, 0],
            [324, 100, 0]
        ],
        name: "sunset"
    }
]

export const classicRepresentation = [
    [48, 100, 50],
    [36, 100, 50],
    [41, 100, 77],
    [47, 100, 50]
]
