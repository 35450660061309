import { SetDeviceColorPayload, SetDeviceStatusPayload, GetAvailableDevicesPayload } from "@store/models";

export const GET_AVAILABLE_DEVICES = "get_available_devices";
export const SET_DEVICE_COLOR = "set_device_color";
export const SET_DEVICE_STATUS = "set_device_status";
export const FETCH_AVAILABLE_DEVICES = "fetch_available_devices";
export const DEVICE_LOST = "device_lost"
export const SELECT_DEVICE = "select_device";
export const DESELECT_DEVICE = "deselect_device";
export const ADD_MESSAGING_OBJECT = "add_messaging_object";

interface GetAvailableDevices {
  type: typeof GET_AVAILABLE_DEVICES;
  payload: GetAvailableDevicesPayload
}
interface SetDeviceColor {
  type: typeof SET_DEVICE_COLOR;
  payload: SetDeviceColorPayload
}
interface SetDeviceStatus {
  type: typeof SET_DEVICE_STATUS;
  payload: SetDeviceStatusPayload
}
interface FetchAvailableDevices {
  type: typeof FETCH_AVAILABLE_DEVICES;
  payload:  any // FIXME: remove any
}
interface SelectDevice {
  type: typeof SELECT_DEVICE;
  payload: any // FIXME: remove any
}
interface DeselectDevice {
  type: typeof DESELECT_DEVICE;
  payload: any // FIXME: remove any
}
interface AddMessagingObject {
  type: typeof ADD_MESSAGING_OBJECT;
  payload: any // FIXME: remove any
}

interface DeviceLost {
  type: typeof DEVICE_LOST;
  payload: any // FIXME: remove any
}

export type DeviceActionTypes =
  | GetAvailableDevices
  | SetDeviceColor
  | SetDeviceStatus
  | FetchAvailableDevices
  | SelectDevice
  | DeselectDevice
  | AddMessagingObject
  | DeviceLost;
