import React, { useState, useEffect, Fragment } from "react"
import { withRouter, RouteComponentProps } from "react-router"
import { Typography, notification } from "antd"
import { getWindowDimensions } from "@store/selectors"
import { useSelector, useDispatch } from "react-redux"
import QrReader from "react-qr-reader"
import { landing_route } from "@lib/constants"
import { IconCard } from "@common/components/_Card"
import initConnectionManager from "@api/ConnectionManager"
import { clamp } from "lodash"
const styles = require("./errors.module.less")

type Props = {} & RouteComponentProps
const { Title, Text } = Typography
const noUrl = "No url found"
const invalidUrl = "Invalid Url"

const Error503 = withRouter((props: Props) => {
    const dimensions = useSelector(getWindowDimensions)
    const [url, setUrl] = useState(noUrl)
    const [token, setToken] = useState("")
    const [openCamera, setShowOpenCamera] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        if (url && url !== noUrl) {
            // notification.open({
            //   key: "showLink",
            //   message: "Open Link",
            //   description: url,
            //   onClick: openLink,
            //   style: {
            //     color: "#000"
            //   },
            //   placement: "bottomRight"
            // });
            openLink()
        }
    }, [url])

    const openLink = () => {
        if (token) {
            notification.close("showLink")
            return props.history.push({
                pathname: landing_route,
                search: "?t=" + token
            })
        } else return setUrl(invalidUrl)
    }
    const handleQRCError = (error: Error) => {
        setShowOpenCamera(true)
    }
    const handleQRCUrl = (url: string | null) => {
        if (url) {
            const urlParams = new window.URL(url).searchParams
            const token = urlParams.get("t")
            if (token) {
                setToken(token)
                initConnectionManager(dispatch, token)
                setUrl(url)
            } else {
                setToken("")
                setUrl(invalidUrl)
            }
        }
        console.log(url)
    }
    return (
        <div className={styles["error-503-container"]}>
            <IconCard
                icon="scan-qr-code"
                color="@white"
                type="custom"
                size={clamp(dimensions.width * 0.5, 150, 200)}
            />
            <Title level={4}>You are not connected.</Title>
            {!openCamera && (
                <Fragment>
                    <Text style={{ marginBottom: "10px" }}>
                        Scan QR code again.
                    </Text>
                    <QrReader
                        onScan={handleQRCUrl}
                        onError={handleQRCError}
                        className={styles["qr-reader"]}
                    />
                </Fragment>
            )}
            {openCamera && (
                <Text style={{ fontSize: "0.6em + 1vw" }}>
                    Go to your camera and rescan the QR code
                </Text>
            )}
        </div>
    )
})
export { Error503 }
