import React, {
    createRef,
    useEffect,
    useState,
    Fragment,
    CSSProperties
} from "react"
import { Row, Col, Typography, Button } from "antd"
import { Icon } from "../Icon"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import { useSelector } from "react-redux"
import { getAuthService } from "@store/selectors"

const { Title } = Typography
const style = require("./banner.module.less")

const Banner = () => {
    const container = createRef<HTMLDivElement>()
    const { authenticated, authenticating } = useSelector(getAuthService)

    const [size, setSize] = useState(60)
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (!authenticating && authenticated) {
            setTimeout(() => {
                setShow(true)
            }, 3000)
        } else {
            setShow(true)
        }
    }, [])

    useEffect(() => {
        if (container.current) {
            setSize(container.current.clientHeight)
        }
    }, [container.current])

    return (
        <Fragment>
            {show && (
                <div className={style["banner-container"]}>
                    <Icon icon="meshle-full" size={size} />

                    <Title level={4} className={style["title"]}>
                        <FormattedMessage id="app.room.banner" />
                    </Title>

                    <Button
                        href="https://meshle.com/hotel/learn-more"
                        target="blank"
                        className={style["button"]}
                        ghost
                        shape="round"
                    >
                        <FormattedHTMLMessage id="app.more.link" />
                    </Button>
                </div>
            )}
        </Fragment>
    )
}

export { Banner }
