import React from "react";
import { Slider } from "antd-mobile";
import { SliderProps } from "antd-mobile/lib/slider";

export type MeshleSliderProps = {} & SliderProps;

const MeshleSlider = (props: MeshleSliderProps) => {
  return <Slider {...props} />;
};
export { MeshleSlider as Slider };
