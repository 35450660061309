import { HSVColor } from "@store/models"
import image from "./wheel.png"
import { HSLColor } from "./ColorWheel"
import { hsv2hsl } from "@lib/utils/color"
declare global {
    interface CanvasRenderingContext2D {
        fillPixel(x: number, y: number, color: string): CanvasRenderingContext2D
    }
    interface HTMLElement {
        getPosition(): {
            x: number
            y: number
            width: number
            height: number
        }
        removeEventListeners(
            eventNames: string,
            listener: (e: Event) => void
        ): void
        addEventListeners(
            eventNames: string,
            listener: (e: Event) => void
        ): void
    }
}

export {}

CanvasRenderingContext2D.prototype.fillPixel = function(
    x: number,
    y: number,
    color: string
) {
    this.beginPath()
    this.rect(x, y, 1, 1)
    this.fillStyle = color
    this.fill()
    this.closePath()
    return this
}

HTMLElement.prototype.addEventListeners = function(
    eventNames: string,
    listener: (e: Event) => void
) {
    const el = this
    const events = eventNames.split(" ")
    for (let i = 0, iLen = events.length; i < iLen; i++) {
        el.addEventListener(events[i], listener, true)
    }
}

HTMLElement.prototype.removeEventListeners = function(
    eventNames: string,
    listener: (e: Event) => void
) {
    const el = this
    const events = eventNames.split(" ")
    for (let i = 0, iLen = events.length; i < iLen; i++) {
        el.removeEventListener(events[i], listener, true)
    }
}

HTMLElement.prototype.getPosition = function() {
    const el = this
    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return {
        y: rect.top + scrollTop,
        x: rect.left + scrollLeft,
        width: rect.width,
        height: rect.height
    }
}

function hslToHex(h, s, l) {
    let r, g, b
    h /= 360
    s /= 100
    l /= 100

    if (s == 0) {
        r = g = b = l
    } else {
        const hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }

    const rgbToHex = function(rgb) {
        const hex = Number(rgb).toString(16)
        return hex.length < 2 ? "0" + hex : hex
    }

    return (
        "#" +
        rgbToHex(Math.round(r * 255)) +
        rgbToHex(Math.round(g * 255)) +
        rgbToHex(Math.round(b * 255))
    )
}
export const drawCanvas = (
    knob: HTMLDivElement,
    size: number,
    initialColor: HSVColor
) => {
    const rad = size / 2
    const renderIntialKnob = () => {
        const radius = size / 2
        const colorRadius = initialColor.saturation * radius
        const angle = (1 - initialColor.hue) * (2 * Math.PI)
        const midX = size / 2 //midpoint of the circle
        const midY = size / 2
        const x = Math.cos(angle) * colorRadius //offset from the midpoint of the circle
        const y = Math.sin(angle) * colorRadius
        let color = hsv2hsl(initialColor.hue, initialColor.saturation, 1)
        color = hslToHex(color[0] * 360, color[1] * 100, color[2] * 100)
        handleKnob(knob, x, -y, color, rad) //TODO: change "" to color
    }
    renderIntialKnob()
}

export const changeKnobEventListeners = (
    add = true,
    canvas?: HTMLDivElement,
    knob?: HTMLDivElement,
    onColorChange?: (hexString: HSLColor) => void,
    size?: number
) => {
    let mousedown = false
    const mousedownEvent = function(e) {
        e.preventDefault()
        mousedown = true
        handleTouchEvent(
            canvas!,
            knob!,
            e as MouseEvent | TouchEvent,
            size!,
            onColorChange!
        )
    }
    const mouseMoveEvent = function(e) {
        if (!mousedown) return false
        handleTouchEvent(
            canvas!,
            knob!,
            e as MouseEvent | TouchEvent,
            size!,
            onColorChange!
        )
    }
    const mouseUpEvent = function(e) {
        mousedown = false
    }
    document.body.removeEventListeners("mousedown touchstart", mouseUpEvent)
    document.body.removeEventListeners("mousedown touchstart", mousedownEvent)
    document.body.removeEventListeners("mousemove touchmove", mouseMoveEvent)
    if (add) {
        canvas!.addEventListeners("mousedown touchstart", mousedownEvent)
        document.body.addEventListeners("mouseup touchend", mouseUpEvent)
        document.body.addEventListeners("mousemove touchmove", mouseMoveEvent)
    }
}

const handleTouchEvent = (
    canvas: HTMLDivElement,
    knob: HTMLDivElement,
    e: MouseEvent | TouchEvent,
    size: number,
    onColorChange: (hexString: HSLColor) => void
) => {
    const el_pos = canvas.getPosition()
    let m_pos: { x: number; y: number } = { x: 0, y: 0 }
    let rad = size / 2
    if (window.TouchEvent && e instanceof TouchEvent) {
        m_pos = { x: e.touches[0].pageX, y: e.touches[0].pageY }
    } else if (window.MouseEvent && e instanceof MouseEvent) {
        m_pos = { x: e.pageX, y: e.pageY }
    }
    rad = el_pos.width / 2
    let x = m_pos.x - el_pos.x - rad
    let y = m_pos.y - el_pos.y - rad

    const a = Math.round(((Math.atan2(y, x) * 180) / Math.PI) * 100) / 100
    let d = Math.sqrt(x * x + y * y) / rad

    if (d > 1) {
        x = x * (1 / d)
        y = y * (1 / d)
    }

    d = Math.min(d, 1)
    const l = Math.round(((1 - d) * 50 + 50) * 100) / 100
    const color = hslToHex(a, 100, l)
    handleKnob(knob, x, y, color, rad)
    onColorChange({ h: a, s: 100, l })
}

const handleKnob = (
    knob: HTMLDivElement,
    x: number,
    y: number,
    color: string,
    rad: number
) => {
    knob.style.top = ((y + rad) / (rad * 2)) * 100 + "%"
    knob.style.left = ((x + rad) / (rad * 2)) * 100 + "%"
    knob.style.backgroundColor = color
}
