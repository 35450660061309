import { BlurAppPayload, SetWindowDimensionsPayload, SetDeviceUniqueIdPayload } from "@store/models";

export const BLUR_APP = "blur_app";
export const SET_WINDOW_DIMENSIONS = "set_window_dimensions";
export const SET_USER_UNIQUE_ID = "set_user_unique_id";

interface BlurApp {
  type: typeof BLUR_APP;
  blur: BlurAppPayload;
}

interface SetWindowDimensions {
  type: typeof SET_WINDOW_DIMENSIONS;
  payload: SetWindowDimensionsPayload;
}

interface SetDeviceUniqueId {
  type: typeof SET_USER_UNIQUE_ID;
  payload: SetDeviceUniqueIdPayload;
}
export type MainActionTypes = BlurApp | SetWindowDimensions | SetDeviceUniqueId;
