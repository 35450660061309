import { createSelector } from "reselect";
import { AppState } from "@lib/models/index";
import { MainState } from "@store/reducers/main.reducer";

const getMain = (state: AppState) => state.main;

export const getBlur = createSelector(
  [getMain],
  main => (main ? main.blur : false)
);
export const getWindowDimensions = createSelector(
  [getMain],
  main =>
    main.windowDimensions
      ? main.windowDimensions
      : ({} as MainState["windowDimensions"])
);

export const getUserUid = createSelector(
  [getMain],
  main => main ? main.userUid : ""
)