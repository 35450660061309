module.exports = {
    colors: {
        transparent: "transparent",
        white: "#ffffff",
        black: "#000000",
        gray: {
            "100": "#F7FAFC",
            "200": "#EDF2F7",
            "300": "#E2E8F0",
            "400": "#CBD5E0",
            "500": "#A0AEC0",
            "600": "#718096",
            "700": "#4A5568",
            "800": "#2D3748",
            "900": "#1A202C"
        },
        purple: {
            "100": "#FAF5FF",
            "200": "#E9D8FD",
            "300": "#D6BCFA",
            "400": "#B794F4",
            "500": "#9F7AEA",
            "600": "#484C87",
            "700": "#6B46C1",
            "800": "#553C9A",
            "900": "#0E103C"
        },
        yellow: {
            "100": "#FFFFF0",
            "200": "#FEFCBF",
            "300": "#FAF089",
            "400": "#F6E05E",
            "500": "#ECC94B",
            "600": "#D69E2E",
            "700": "#B7791F",
            "800": "#975A16",
            "900": "#744210"
        },
        red: {
            "100": "#FFF5F5",
            "200": "#FED7D7",
            "300": "#FEB2B2",
            "400": "#FC8181",
            "500": "#F56565",
            "600": "#E53E3E",
            "700": "#C53030",
            "800": "#9B2C2C",
            "900": "#742A2A"
        },
        teal: {
            "100": "#E6FFFA",
            "200": "#B2F5EA",
            "300": "#81E6D9",
            "400": "#4FD1C5",
            "500": "#38B2AC",
            "600": "#319795",
            "700": "#2C7A7B",
            "800": "#285E61",
            "900": "#234E52",
            "1000": "#234E52"
        },
        transparent_white: {
            "10": "rgba(255,255,255,0.5)"
        }
    },
    spacing: {
        px: "1px",
        "0": "0",
        "1": "0.25rem",
        "2": "0.5rem",
        "3": "0.75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "8": "2rem",
        "10": "2.5rem",
        "12": "3rem",
        "16": "4rem",
        "20": "5rem",
        "24": "6rem",
        "32": "8rem",
        "40": "10rem",
        "48": "12rem",
        "56": "14rem",
        "64": "16rem"
    },
    boxShadow: {
        default:
            "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        md:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        lg:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        xl:
            "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
        "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
        inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
        outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
        none: "none"
    },
    fontWeight: {
        hairline: "100",
        thin: "200",
        light: "300",
        normal: "400",
        medium: "500",
        semibold: "600",
        bold: "700",
        extrabold: "800",
        black: "900"
    },
    fontSize: {
        xs: "calc(0.5rem + 1vmin)",
        sm: "calc(0.7rem + 1vmin)",
        base: "calc(0.8rem + 1vmin)",
        lg: "calc(1rem + 1vmin)",
        xl: "(1.2rem + 1vmin)",
        xxl: "calc(1.4rem + 1vmin)",
        "2xl": "calc(1.5rem + 1vmin)",
        "3xl": "calc(1.875rem + 1vmin)",
        "4xl": "calc(2.25rem + 1vmin)",
        "5xl": "calc(3rem + 1vmin)",
        "6xl": "calc(4rem + 1vmin)"
    },
    borderRadius: {
        none: "0",
        sm: "0.125rem",
        default: "0.25rem",
        lg: "0.5rem",
        full: "9999px"
    },
    borderWidth: {
        default: "1px",
        "0": "0",
        "2": "2px",
        "4": "4px",
        "8": "8px"
    },
    backgroundSize: {
        auto: "auto",
        cover: "cover",
        contain: "contain"
    }
}
