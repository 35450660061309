import { BLUR_APP, SET_WINDOW_DIMENSIONS, SET_USER_UNIQUE_ID } from "@store/action-types";
import { BlurAppPayload, SetWindowDimensionsPayload, SetDeviceUniqueIdPayload } from "@store/models";

export const blurApp = (blur: BlurAppPayload) => ({
  type: BLUR_APP,
  blur
});
export const setWindowDimensions = (payload: SetWindowDimensionsPayload) => ({
  type: SET_WINDOW_DIMENSIONS,
  payload
});

export const setUserUniqueId = (payload: SetDeviceUniqueIdPayload) => ({
  type: SET_USER_UNIQUE_ID,
  payload
})