import React, { useReducer, useEffect } from "react"
import { Navigator } from "@common/components/Navigator"
import { addUrlProps } from "react-url-query"
import initConnectionManager from "@api/ConnectionManager"
const styles = require("@common/styles/App.module.less")
import "@common/styles/styles.less"
import { useDispatch, useSelector } from "react-redux"
import { setWindowDimensions, setUserUniqueId } from "@store/action-creators"
import { getAuthService } from "@store/selectors"
import { ObjectPropsType } from "@lib/utils"
import { get, x64hash128 } from "fingerprintjs2"
import CacheBuster from "./CacheBuster"

type Props = {} & ReturnType<typeof mapUrlToProps>
type State = {
    loading: boolean
    error: boolean
    sessionTimeout: boolean
    modalVisible: boolean
}
const initState = {
    loading: true,
    modalVisible: true,
    error: false,
    sessionTimeout: false
}
const reducer = (state: State, property: ObjectPropsType<State>) => ({
    ...state,
    ...property
})

const App = (props: Props) => {
    const [state, setState] = useReducer(reducer, initState)
    const dispatch = useDispatch()
    const authService = useSelector(getAuthService)
    useEffect(() => {
        setTimeout(() => {
            get(components => {
                const deviceUid = x64hash128(
                    components.reduce((acc, cur) => acc + cur.value, ""),
                    components.length
                )
                console.log(deviceUid)

                dispatch(setUserUniqueId(deviceUid))
                const { token } = props
                initConnectionManager(dispatch, token)
            })
        }, 500)
    }, [])

    useEffect(() => {
        const resizeListener = () => {
            dispatch(
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                })
            )
        }
        resizeListener()
        window.addEventListener("resize", resizeListener)
        return () => window.removeEventListener("resize", resizeListener)
    }, [])

    return (
        <div className={styles["app-container"]}>
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload()
                    }
                    return (
                        <Navigator
                            loading={authService.authenticating || loading}
                        />
                    )
                }}
            </CacheBuster>
        </div>
    )
}

function mapUrlToProps(url: any) {
    return {
        token: url.t
    }
}

export default addUrlProps({ mapUrlToProps })(App)
