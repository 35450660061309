import {
  AddMessagingServicePayload,
  ChangeAuthStatePayload,
  AddInternalizationServicePayload
} from "@store/models";

export const ADD_MESSAGING_SERVICE = "add_messaging_service";
export const CHANGE_AUTH_STATE = "change_auth_state";
export const ADD_INTERNATIONALIZATION_SERVICE = "add_internalization_service";
interface AddMessagingService {
  type: typeof ADD_MESSAGING_SERVICE;
  payload: AddMessagingServicePayload;
}
interface ChangeAuthState {
  type: typeof CHANGE_AUTH_STATE;
  payload: ChangeAuthStatePayload;
}
interface AddInternalizationService {
  type: typeof ADD_INTERNATIONALIZATION_SERVICE;
  payload: AddInternalizationServicePayload;
}
export type ServiceActionTypes =
  | AddMessagingService
  | ChangeAuthState
  | AddInternalizationService;
