import { deviceActionTypes } from "@store/action-types";
import produce from "immer";
import { HSVColor } from "@store/models";

export type SelectionState = {
  deviceId?: number;
  color: HSVColor;
};
const init_state: SelectionState = {
  color: {} as HSVColor
};

export default (state = init_state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case deviceActionTypes.SELECT_DEVICE:
        const { deviceId, hsb } = action.payload;
        if (typeof deviceId !== undefined && hsb) {
          draft.deviceId = deviceId;
          draft.color = hsb;
        }
        return draft;

      case deviceActionTypes.DESELECT_DEVICE:
        draft = init_state;
        return draft;
    }
  });
