import React, { CSSProperties } from "react";
import { Slider, MeshleSliderProps } from "./slider.component";
const styles = require("./slider.module.less");

type Props = {
  visible: boolean;
  startCords: number[];
  color: string;
  value: number;
} & MeshleSliderProps;

const BrightnessSlider = (props: Props) => {
  return (
    <div
      className={styles["brightness-slider-container"]}
      style={
        props
          ? ({
            "--scale": props.visible ? 1 : 0,
            "--opacity": props.visible ? 1 : 0,
            "--transform-origin": props.startCords
              ? `${props.startCords[0]}px ${props.startCords[1]}px`
              : "50% 50%",
            "--padding-top": props.startCords
              ? `${props.startCords[1] - 30}px`
              : "50%"
          } as CSSProperties)
          : {}
      }
    >
      <Slider
        handleStyle={{ display: "none" }}
        trackStyle={{
          height: "60px",
          borderRadius: "0.5rem",
          backgroundColor: props.color ? props.color : "blue"
        }}
        railStyle={{
          backgroundColor: "transparent",
          border: "1px solid white",
          height: "60px",
          borderRadius: "0.5rem"
        }}
        {...props}
        value={props && props.value ? props.value : 0}
      />
    </div>
  );
};
export { BrightnessSlider };
