import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"

import App from "./App"
import "@common/styles/index.less"
import store from "@store/index"
import loadLang from "@i18n/index"
import * as serviceworker from "./registerServiceWorker"
import { BrowserRouter } from "react-router-dom"

const language = navigator.language.split(/[-_]/)[0] // language without region code
const app = document.getElementById("root")

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider
            defaultLocale="de"
            locale={language}
            messages={loadLang(language)}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </IntlProvider>
    </Provider>,
    app
)
serviceworker.register()
